import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import { Button, Icon } from '@/shared/ui';

import { REFRESH_INTERVAL_IN_MILLISECONDS } from '@/widgets/RefreshPage/config/refreshPage.const';

import styles from './RefreshPage.module.scss';

type RefreshPageProps = {
  className?: string;
};

type RefreshLog = {
  timestamp: number;
  serverVersion: string;
  clientVersion: string;
};

const LOGGING_THRESHOLD = 2; // Time to attempts refresh
const LOGGING_TIME_WINDOW = 5 * 60 * 1000; // 5 minutes in milliseconds
const STORAGE_KEY = 'refresh_page_logs';

export const RefreshPage: FC<RefreshPageProps> = (props) => {
  const { className } = props;

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const logRefreshAttempt = (serverVer: string, clientVer: string) => {
    const currentTime = Date.now();
    const storedLogs = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');

    // Remove logs older than time window
    const recentLogs = storedLogs.filter(
      (log: RefreshLog) => currentTime - log.timestamp < LOGGING_TIME_WINDOW,
    );

    // Add new log
    recentLogs.push({
      serverVersion: serverVer,
      clientVersion: clientVer,
      timestamp: currentTime,
    });

    localStorage.setItem(STORAGE_KEY, JSON.stringify(recentLogs));

    // Check if threshold is reached
    if (recentLogs.length >= LOGGING_THRESHOLD) {
      Sentry.captureMessage('Multiple refresh page attempts detected', {
        extra: {
          logs: recentLogs,
          timeWindow: `${LOGGING_TIME_WINDOW / 60000} minutes`,
        },
        level: 'warning',
      });

      // Reset logs after reporting
      localStorage.removeItem(STORAGE_KEY);
    }
  };

  const isClientVersionEqualServerVersion = async () => {
    const url = window.location.origin;

    try {
      const response = await axios.get(url, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });

      const html = new DOMParser().parseFromString(response.data, 'text/html');

      const serverVersion = (
        html.getElementsByName('version')[0] as HTMLMetaElement
      )?.content;
      const clientVersion = (
        document.getElementsByName('version')[0] as HTMLMetaElement
      )?.content;

      if (!serverVersion && !clientVersion) {
        return;
      }

      const needToRefreshPage =
        !!serverVersion && !!clientVersion && serverVersion !== clientVersion;

      if (needToRefreshPage) {
        logRefreshAttempt(serverVersion, clientVersion);
      }

      setIsVisible(needToRefreshPage);
    } catch (e) {
      console.error(`Version control error!`);
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (!isVisible) {
      const interval = setInterval(
        isClientVersionEqualServerVersion,
        REFRESH_INTERVAL_IN_MILLISECONDS,
      );

      return () => {
        clearInterval(interval);
      };
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className={cn(styles.container, className)}>
      <Icon name="rotate" size={32} className={styles.refreshIcon} />
      <h5 className={cn(styles.title, 'h5')}>
        <FormattedMessage
          id="refreshPage.title"
          defaultMessage="Refresh the page"
        />
      </h5>
      <p className={cn(styles.body, 'p2')}>
        <FormattedMessage
          id="refreshPage.body"
          defaultMessage="Please refresh page to reload the view and clear browser cache. Reloading would allow us to correctly display all Diagnocat features"
        />
      </p>
      <Button
        variant="secondary"
        size="small"
        onClick={() => window.location.reload()}
      >
        <FormattedMessage
          id="refreshPage.refreshButton"
          defaultMessage="Refresh now"
        />
      </Button>
    </div>
  );
};
